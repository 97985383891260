@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* HierarchicalList.css */

.hierarchical-list ul {
  list-style-type: none; /* Remove default list styling */
  padding-left: 20px; /* Indent nested lists */
}

.hierarchical-list .top-level {
  font-weight: bold;
  font-size: 24px; /* Adjust the size as needed */
}

.hierarchical-list li {
  margin-bottom: 5px; /* Space between list items */
}

/* Optionally, you can add styles for nested levels if needed */
/* .hierarchical-list ul ul {
  padding-left: 20px; 
  font-weight:500;
  font-size: 16px; 
  color:chocolate;
} */

/* First Level */
.hierarchical-list > ul > li {
  font-weight: bold;
  font-size: 28px; /* Adjust the size as needed */
}

/* Second Level */
.hierarchical-list > ul > li > ul > li {
  padding-left: 20px; /* Indent */
  font-weight: 500;
  font-size: 26px; /* Adjust the size as needed */
  color: rgb(63, 210, 30);

}

/* Third Level */
.hierarchical-list > ul > li > ul > li > ul > li {
  padding-left: 20px; /* Further indent */
  font-weight: 500;
  font-size: 24px; /* Adjust the size as needed */
  color: rgb(210, 30, 141);
}

/* Fourth Level */
.hierarchical-list > ul > li > ul > li > ul > li > ul > li {
  padding-left: 20px; /* Further indent */
  font-weight: 500;
  font-size: 22px; /* Adjust the size as needed */
  color: rgb(30, 153, 210);
}

/* Fifth Level */
.hierarchical-list > ul > li > ul > li > ul > li > ul > li > ul > li {
  padding-left: 20px; /* Further indent */
  font-weight: 500;
  font-size: 20px; /* Adjust the size as needed */
  color: rgb(210, 30, 30);
}

/* Sixth Level */
.hierarchical-list > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li {
  padding-left: 20px; /* Further indent */
  font-weight: 500;
  font-size: 18px; /* Adjust the size as needed */
  color: rgb(30, 210, 153);
}

/* Seventh Level */
.hierarchical-list > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li {
  padding-left: 20px; /* Further indent */
  font-weight: 500;
  font-size: 16px; /* Adjust the size as needed */
  color: rgb(210, 210, 30);
}

/* .hierarchical-list > ul > li,*/
/* .hierarchical-list > ul > li > ul > li ,
.hierarchical-list > ul > li > ul > li > ul > li, */
.hierarchical-list > ul > li > ul > li > ul > li > ul > li, 
.hierarchical-list > ul > li > ul > li > ul > li > ul > li > ul > li, 
.hierarchical-list > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li,
.hierarchical-list > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li > ul > li {
  display:none;
} 

/* Custom styles using Tailwind CSS classes */
.category-checkbox:checked + .category-label-span {
  @apply font-semibold;
}

.category-title {
  background-color: #394d74;
  background-color: #1c8b97;
}

 .btn.btn-info.btn-disabled, .btn.btn-info[disabled], .btn.btn-info:disabled {
  background-color: rgba(0, 158, 204, 0.4) !important; /* Using your info color with 60% opacity */
  border:none !important;
  opacity: 1 !important;
}

.btn-error:is(.btn-disabled, [disabled], :disabled) {
  background-color: rgba(239, 68, 68, 0.4) !important; /* Using a standard error red with 60% opacity */
  border:none !important;
  opacity: 1 !important;
}
